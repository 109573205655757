<template>
  <!-- 详情与编辑、添加都放同一页  优惠券 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">基本信息</p>
      <el-form
        class="mt-24"
        label-width="100px"
        :model="cou_form"
        :rules="rules"
        ref="cou_form"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            class="form-input"
            placeholder="请输入活动标题"
            clearable
            v-model="cou_form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="适用品类" prop="couponClassifyName">
          <el-select
            placeholder="选择品类"
            v-model="cou_form.couponClassifyName"
            filterable
            class="form-input"
            @change="couponClassifyChange($event)"
          >
            <el-option
              v-for="(item, index) in cateList"
              :key="index"
              :value="item.classifyId"
              :label="item.classifyName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券图片" prop="picture">
          <UploadPics
            :uploadType="'pics'"
            :imgLimit="6"
            :imgSize="'750*400，图片底部预留60px'"
            :imgsPreview="cou_form.picture"
            @getImgData="getImgData"
          />
        </el-form-item>
        <el-form-item label="类型" prop="coupon">
          <span class="ml-20">线下优惠卷：</span>
          <el-radio-group
            v-model="cou_form.coupon"
            class=" ml-10"
            @change="couponTypeChange"
          >
            <el-radio :label="3">餐饮优惠券</el-radio>
            <el-radio :label="4">折扣券</el-radio>
            <el-radio :label="5">代金券</el-radio>
            <el-radio :label="6">停车券（默认所有停车场通用）</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 根据优惠券类型 显示不同的输入 -->
        <el-form-item
          :label="cou_form.coupon == 4 ? '折扣' : '减免金额'"
          prop="reducedAmount"
        >
          <el-input
            v-model.number="cou_form.reducedAmount"
            placeholder="请输入0~10之间数字"
            class="search-input"
            v-if="cou_form.coupon == 4"
          >
            <template slot="append">折</template>>
          </el-input>
          <el-input
            v-model="cou_form.reducedAmount"
            placeholder="请输入减免金额"
            class="search-input"
            v-else
          >
            <template slot="append">元</template>>
          </el-input>
        </el-form-item>
        <el-form-item label="使用条件" prop="useCondition">
          <el-radio v-model="cou_form.useCondition" :label="1">不限制</el-radio>
          <el-radio
            v-model="cou_form.useCondition"
            :label="2"
            style="margin-right:10px"
            >满</el-radio
          >
          <el-input
            placeholder="输入金额"
            v-model="cou_form.fullMoney"
            class="search-input"
            v-if="cou_form.useCondition == 2"
          >
            <template slot="append">元可用</template>
          </el-input>
        </el-form-item>
        <el-form-item label="适用门店" prop="suitStoreType">
          <el-row>
            <el-col :span="2"
              ><el-radio
                :disabled="cou_form.coupon == 2"
                v-model="cou_form.suitStoreType"
                :label="1"
                >全部门店</el-radio
              ></el-col
            >
            <el-col :span="2"
              ><el-radio v-model="cou_form.suitStoreType" :label="2"
                >指定门店</el-radio
              ></el-col
            >
            <el-col :span="6" v-if="cou_form.suitStoreType == 2">
              <el-form-item prop="storeId">
                <el-select
                  class="form-input"
                  placeholder="选择门店"
                  v-model="cou_form.storeId"
                  @change="getMerchantsListHandle"
                >
                  <el-option
                    v-for="(item, index) in storeList"
                    :key="index"
                    :label="item.storeName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
          label="适用商户"
          v-show="
            cou_form.coupon &&
              cou_form.suitStoreType == 2 &&
              cou_form.coupon != 2 &&
              cou_form.coupon != 1
          "
          prop="suitMerchantType"
        >
          <el-row>
            <el-col :span="2"
              ><el-radio v-model="cou_form.suitMerchantType" :label="1"
                >全部商户</el-radio
              ></el-col
            >
            <el-col :span="2"
              ><el-radio v-model="cou_form.suitMerchantType" :label="2"
                >指定商户</el-radio
              ></el-col
            >
            <el-col :span="6" v-if="cou_form.suitMerchantType == 2">
              <el-form-item prop="merchantList">
                <el-select
                  class="form-input"
                  placeholder="选择商户"
                  v-model="merchantIds"
                  multiple
                  filterable
                  @change="merchantChange"
                >
                  <el-option
                    v-for="(item, index) in merchantList"
                    :key="index"
                    :label="item.merchantName"
                    :value="item.merchantId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="适用商品">
          <el-radio v-model="cou_form.cou_goods" :label="0">全部商品</el-radio>
          <el-radio v-model="cou_form.cou_goods" :label="1">指定商品</el-radio>
          <el-select
            class="form-input"
            placeholder="选择商品"
            v-model="cou_form.goods"
            multiple
            v-show="cou_form.cou_goods==1"
          >
            <el-option v-for="(item,index) in goods_list" :key="index" :label="item" :value="index"></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item
          v-if="cou_form.coupon == 2"
          label="活动商品"
          prop="goodsList"
          :rules="[
            { required: true, trigger: 'blur', message: '活动商品不能为空' },
          ]"
        >
          <div>
            <el-button
              type="primary"
              plain
              @click="selectGoods(cou_form.goodsList)"
              >{{
                cou_form.goodsList && cou_form.goodsList.length >= 1
                  ? "重新选择商品"
                  : "添加商品"
              }}</el-button
            >
          </div>
          <el-table
            class="mt-24 member-table"
            :data="cou_form.goodsList"
            width="70%"
            v-if="cou_form.goodsList.length > 0"
          >
            <el-table-column
              :label="item.value"
              v-for="item in tableHeader"
              :key="item.key"
            >
              <template slot-scope="scope">
                <el-avatar
                  v-if="item.key == 'imgUrl'"
                  :src="picsPath(scope.row.imgUrl || scope.row.goodsImgs)"
                  shape="square"
                  alt="加载失败"
                  :key="scope.row.imgUrl || scope.row.picture"
                ></el-avatar>
                <!-- <template v-if="item.key=='imgUrl'">{{ scope.row}} </template> -->
                <template v-else-if="item.key == 'barCode'"
                  >{{ scope.row[item.key] || scope.row["goodsCode"] }}
                </template>
                <template v-else>{{ scope.row[item.key] }}</template>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <span
                    class="ml-10 tip-text-delete"
                    @click="deleteGoods(scope.$index)"
                    >删除</span
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">其他规则</p>
        <el-form-item label="发放数量" prop="quantityIssued">
          <el-input
            placeholder="请输入发放数量"
            class="search-input"
            v-model.number="cou_form.quantityIssued"
          >
            <template slot="append">张</template>
          </el-input>
        </el-form-item>
        <el-form-item label="领取条件" prop="drawCondition">
          <el-row>
            <el-col :span="2"
              ><el-radio v-model="cou_form.drawCondition" :label="1"
                >免费领取</el-radio
              ></el-col
            >
            <el-col :span="2"
              ><el-radio v-model="cou_form.drawCondition" :label="2"
                >每人限领</el-radio
              ></el-col
            >
            <el-col :span="6" v-if="cou_form.drawCondition == 2">
              <el-form-item prop="limitAmount">
                <el-input
                  placeholder="输入数量"
                  class="search-input"
                  v-model.number="cou_form.limitAmount"
                >
                  <template slot="append">张</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-form-item label="核销方式" prop="usageMode">
          <el-radio v-model="cou_form.usageMode" :label="0">核销码（到店使用）</el-radio>
          <el-radio v-model="cou_form.usageMode" :label="1" :disabled="true">线上使用（该功能规划中，目前无法使用）</el-radio>
        </el-form-item> -->
        <el-form-item
          label="有效期"
          :prop="
            cou_form.validityType == 1 ? 'effectiveTime' : 'effectiveInterval'
          "
        >
          <div>
            <el-row>
              <el-col :span="2"
                ><el-radio
                  style="margin-right:10px"
                  v-model="cou_form.validityType"
                  :label="1"
                  >固定日期</el-radio
                ></el-col
              >
              <el-col :span="6" v-if="cou_form.validityType == 1">
                <el-form-item prop="effectiveTime">
                  <el-date-picker
                    v-model="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="getDateTime"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="mt-15">
            <el-row>
              <el-col :span="2"
                ><el-radio
                  style="margin-right:10px"
                  v-model="cou_form.validityType"
                  :label="2"
                  >领取后</el-radio
                ></el-col
              >
              <el-col :span="16" v-if="cou_form.validityType == 2">
                <el-form-item prop="effectiveInterval">
                  <el-input
                    placeholder="请输入天数"
                    class="search-input"
                    v-model="cou_form.effectiveInterval"
                  >
                    <template slot="append">天</template>
                  </el-input>
                  <span>生效，有效天数</span>
                  <el-input
                    placeholder="请输入天数"
                    class="search-input"
                    v-model="cou_form.effectiveDay"
                  >
                    <template slot="append">天</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form-item>
        <el-form-item label="优惠券介绍">
          <tinymce v-model="cou_form.couponDetails" :height="300" />
        </el-form-item>
      </el-form>
      <!-- 按钮区域 -->
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="$router.go(-1)"
          >取消</el-button
        >
        <el-button class="form-button" type="primary" @click="submit"
          >确定</el-button
        >
      </div>
      <!-- 活动商品时  点击添加商品时的弹框  选中商品券时  @getTableList="getTableList" -->
      <DepartmentGoodsDialog
        ref="GoodsDialog"
        :hiddenSelectedStore="true"
        :tableHeader="tableHeader"
        :searchArr="searchArr"
        :storeId="String(cou_form.storeId)"
        :selectArr="afferentArr"
        @getSelectedList="getSelectedList"
      ></DepartmentGoodsDialog>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import {
  subCouponsFormOld,
  getCouponDetail,
  getCouponClassify,
} from "@/api/maketing/coupons";
import {
  getStoreList,
  getProcudtList,
} from "@/api/online_department_store/commodity_manage";
import { getMerchantsList } from "@/api/repast/commodity_manage"; //查询餐饮类别下的商户 根据门店id
import { getMerchantsListByStoreId } from "@/api/online_department_store/commodity_manage"; //门店下的商户信息(不分页）
export default {
  components: {
    Tinymce,
    UploadPics,
    DepartmentGoodsDialog,
  },
  data() {
    return {
      cou_form: {
        picture: "", //图片
        name: "", // 名称
        couponClassifyId: "", //优惠券分类Id
        couponClassifyName: "", //优惠券分类名称
        coupon: "", //优惠券类型
        reducedAmount: "", // 减免金额
        useCondition: 1, // 使用条件选择
        fullMoney: "", // 满多少减
        suitStoreType: -1, // 适用门店的单选框value与适用门店
        suitMerchantType: 1, // 适用商户的单选框value与适用商户
        storeId: "", //选择门店
        merchantList: [], //选择商户
        goodsList: [], // 选择适用商品
        quantityIssued: null, // 发放数量限制
        drawCondition: 1, //领取条件选择
        limitAmount: "", // 限领数量
        // usageMode: 1, // 核销方式，一期只有门店核销
        validityType: 1, // 有效期的单选框value
        effectiveTime: "", // 领取后生效日期
        deadline: "", // 有效期截止日期
        effectiveInterval: "", // 领取后生效天数，有效天数
        effectiveDay: "", //有效天数
        couponDetails: ``, // 详情介绍
      },
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        couponClassifyId: [
          { required: true, message: "品类不能为空", trigger: "change" },
        ],
        coupon: [
          { required: true, message: "类型不能为空", trigger: "change" },
        ],
        fullMoney: [{ required: true, message: "不能为空", trigger: "change" }],
        reducedAmount: [
          {
            required: true,
            message: "折扣(减免金额)不能为空",
            trigger: "blur",
          },
        ],
        storeId: [
          { required: true, message: "门店不能为空", trigger: "change" },
        ],
        merchantList: [
          { required: true, message: "商户不能为空", trigger: "change" },
        ],
        picture: [
          { required: true, message: "优惠券图片不能为空", trigger: "blur" },
        ],
        suitStoreType: [{ required: true }],
        suitMerchantType: [{ required: true }],
        quantityIssued: [
          { required: true, message: "发放数量不能为空", trigger: "blur" },
          { type: "number", message: "只能输入数字", min: 0 },
        ],
        drawCondition: [{ required: true }],
        limitAmount: [
          { required: true, message: "每人限额不能为空", trigger: "blur" },
          { type: "number", message: "只能输入数字", min: 0 },
        ],
        useCondition: [{ required: true }],
        usageMode: [{ required: true }],
        validityType: [{ required: true }],
        effectiveTime: [
          { required: true, message: "日期不能为空", trigger: "blur" },
        ],
        effectiveInterval: [
          {
            required: true,
            message: "生效时间及有效天数不能为空",
            trigger: "blur",
          },
        ],
      },
      date: [],
      merchantIds: [],
      cateList: [], // 优惠券品类列表
      storeList: [], // 门店列表
      merchantList: [], // 商户列表
      subImgStr: "",
      flag: true,
      tableHeader: [
        // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "商品图片",
          key: "imgUrl",
        },

        {
          value: "所属门店",
          key: "storeName",
        },
        {
          value: "所属商户",
          key: "merchantName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "price",
        },
        {
          value: "库存",
          key: "stock",
        },
      ],
      searchArr: [
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称",
        },
        {
          name: "goodsClassifyId",
          value: "",
          placeholder: "请选择商品分类",
        },
        {
          name: "merchantId",
          value: "",
          placeholder: "请选择商户",
        },
      ],
      afferentArr: [], //传到子组件  商品列表
    };
  },
  mounted() {
    this.getStores(); //进来先获取门店列表
    this.getTypeData();
  },
  methods: {
    getQuery() {
      if (
        //false是编辑          true是创建新优惠券
        this.$route.query.addFlag == false ||
        this.$route.query.addFlag == "false"
      ) {
        this.flag = this.$route.query.addFlag;
        this.id = this.$route.query.id;
        this.refill(this.$route.query.id);
      }
    },
    refill(id) {
      //查询信息  回填
      getCouponDetail(id).then((res) => {
        let data = res.data.body;
        // console.log("data", data);
        let cou_form = JSON.parse(JSON.stringify(this.cou_form));
        for (let item in cou_form) cou_form[item] = data[item];
        cou_form.couponId = data.couponId;
        // console.log("cateList", this.cateList, data.couponClassifyId);
        if (cou_form.couponClassifyId) {
          cou_form.couponClassifyName = this.cateList.find((ele) => {
            return ele.classifyId == cou_form.couponClassifyId;
          }).classifyName;
        }
        console.log("cou_form", cou_form);
        this.cou_form = cou_form;
        // this.getMerchantsListHandle();
        if (this.cou_form.suitMerchantType == 2 && cou_form.merchantList) {
          //指定商户
          
          this.merchantIds = cou_form.merchantList.map(
            (item) => item.merchantName 
          );
        }
        if (cou_form.validityType == 1)
          this.date = [this.cou_form.effectiveTime, this.cou_form.deadline];
      });
    },
    //优惠券品类下拉框 改变事件
    couponClassifyChange(event) {
      this.cou_form.couponClassifyId = event;
    },
    //优惠券类型切换
    couponTypeChange() {
      // console.log(this.cou_form.coupon);
      if (this.cou_form.coupon == 2) {
        this.cou_form.suitStoreType = 2;
      }
      this.cou_form.storeId = "";
      this.merchantIds = [];
      this.cou_form.merchantList = [];
    },
    //获取优惠券分类列表  适用品类
    getTypeData() {
      getCouponClassify().then((res) => {
        this.cateList = res.data.body.list;
        this.getQuery();
      });
    },
    // 获取门店列表
    getStores() {
      // console.log("获取门店列表");
      getStoreList().then((res) => {
        this.$set(this, "storeList", res.data.body.list);
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      // console.log(this.cou_form.coupon);
      this.merchantIds = [];
      this.cou_form.merchantList = [];
      if (this.cou_form.coupon == 3) {
        //餐饮优惠券
        getMerchantsList(this.cou_form.storeId).then((res) => {
          // console.log(res.data.body);
          this.merchantList = res.data.body;
        });
      } else if (
        this.cou_form.coupon == 4 ||
        this.cou_form.coupon == 5 ||
        this.cou_form.coupon == 6
      ) {
        getMerchantsListByStoreId({ shopId: this.cou_form.storeId }).then(
          (res) => {
            // console.log(res.data.body);
            this.merchantList = res.data.body;
          }
        );
      }
    },
    //   获取已勾选商品
    getSelectedList(data, index) {
      // // console.log(data)
      this.cou_form.goodsList = data;
    },
    // getTableList(data) {
    //   //获取商品列表
    //   getProcudtList(data).then((res) => {
    //     let data = this.$refs.GoodsDialog;
    //     data.total = res.data.body.total;
    //     data.tableData = res.data.body.list;
    //     //进行状态勾选
    //     data.toggleSelection();
    //   });
    // },
    // 打开商品列表弹窗
    selectGoods(products) {
      // // console.log(products, "list");
      if (!this.cou_form.storeId) {
        this.$message({
          message: "请先选择门店",
          type: "warning",
        });
        return;
      }
      let data = this.$refs.GoodsDialog;
      this.afferentArr = products;
      // data.act_form.list = products;
      //  data.selected_temp = JSON.parse(JSON.stringify(products));
      data.showGoods = true;
      data.getGoods(true);
    },
    // 删除已选商品
    deleteGoods(idx) {
      this.cou_form.goodsList.splice(idx, 1);
      this.$refs.GoodsDialog.selected_temp = JSON.parse(
        JSON.stringify(this.cou_form.goodsList)
      );
      this.afferentArr = JSON.parse(JSON.stringify(this.cou_form.goodsList));
    },
    getImgData(imgData, type) {
      this.cou_form.picture = imgData.subImgs;
    },
    getDateTime() {
      this.cou_form.effectiveTime = this.date[0];
      this.cou_form.deadline = this.date[1];
    },
    submit() {
      this.$refs["cou_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    merchantChange(val) {
      //处理商户数据
      let merchantList = [];
      val.forEach((item) => {
        let merchant = this.merchantList.find((i) => i.merchantId == item);
        // console.log(merchant);
        merchantList.push({
          merchantName: merchant.merchantName,
          merchantId: merchant.merchantId,
        });
      });

      this.cou_form.merchantList = merchantList;
    },
    //点击确定提交
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.cou_form));
      // console.log(this.cou_form);
      if (data.coupon == 2) {
        data.goodsList.forEach((ele) => {
          data.merchantList.push({
            merchantId: ele.merchantId,
            merchantName: ele.merchantName,
          });
        });
        data.goodsList = data.goodsList.map((item) => {
          return {
            stock: item.stock,
            price: item.price,
            goodsCode: item.barCode,
            goodsId: item.goodsId,
          };
        });
      } else delete data.goodsList;

      data.type = data.coupon > 2 ? 2 : 1;
      // console.log(data);
      subCouponsFormOld(data).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        }
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.inline {
  display: inline-block;
}
</style>
